import React, { useState, useEffect } from "react";
import Carousel from "../../atoms/carousel";
import styles from "./styles.m.css";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import { Icon } from "../../atoms/icon";
import PropTypes from "prop-types";
import { VikatanResponsiveContainer } from "../ad-free";
const PhotoAlbumImage = ({ storyElement, count, index, showCount, imagedefaultratio = true }) => {
  return (
    <div className={"container"}>
      <div className={styles["image-container"]}>
        {showCount === true && <div className={styles["slide-counter"]}>{`${index}/${count}`}</div>}
        <ResponsiveImageWithFallback
          className={showCount ? styles["image-container-figure"] : styles["image-container-swithout-count"]}
          slug={storyElement["image-s3-key"]}
          metadata={storyElement["image-metadata"]}
          alt={storyElement["image-caption"]}
          imgParams={{ auto: ["format", "compress"] }}
          imagedefaultratio={imagedefaultratio}
          sources={[
            {
              defaultWidth: 650,
              widths: [650],
              sizes: "30vw"
            }
          ]}
        />
        {storyElement["title"] && (
          <div className={styles["text-wrapper"]}>
            <h3 className={styles["image-caption"]}>{storyElement["title"]} </h3>
          </div>
        )}
      </div>

      {!showCount &&
        (index + 1) % 3 === 0 && (
          <VikatanResponsiveContainer
            placement={"text-story"}
            adtype={parseInt(index + 1)}
            storyId={storyElement["id"]}
            className="ad_300_250"
          />
        )}
      {!showCount && index === 1 && <div id="inread-placement-div" />}
    </div>
  );
};

PhotoAlbumImage.propTypes = {
  storyElement: PropTypes.shape({
    "image-s3-key": PropTypes.string,
    "image-metadata": PropTypes.object,
    "image-caption": PropTypes.string,
    title: PropTypes.string
  }),
  count: PropTypes.number,
  index: PropTypes.number,
  showCount: PropTypes.bool,
  imagedefaultratio: PropTypes.bool
};

const PhotoAlbumSlider = ({
  imageElements,
  type,
  className = "",
  onSlideChange: onSlideChangeProp,
  showCount,
  template
}) => {
  const [userType = "F", setType] = useState(false);
  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType || "F");
    });
  }, []);
  function onSlideChange(index) {
    if (!onSlideChangeProp) {
      return;
    }
    if (type === "gallery") {
      onSlideChangeProp(index - Math.floor((index + 1) / 5)); // take into account the ads that are added below
    } else {
      onSlideChangeProp(index);
    }
  }
  let galleryElements = imageElements.map((e, idx) => (
    <PhotoAlbumImage
      storyElement={e}
      index={idx + 1}
      count={imageElements.length}
      key={e.id}
      showCount={template !== "photo"}
      imagedefaultratio={false}
    />
  ));

  return userType && galleryElements.length > 0 ? (
    <div>
      {template === "photo" ? (
        <div
          className={`${styles["album-container"]} ${styles["slider-wrapper"]} ${
            styles["alubum-list-type"]
          } ${className}`}
        >
          {galleryElements}
        </div>
      ) : (
        <div className={`${styles["album-container"]} ${styles["slider-wrapper"]} ${className}`}>
          <Carousel
            className="photo-album"
            options={{
              type: "carousel",
              autoplay: false,
              perView: 1,
              classes: {
                activeSlide: styles["is-card-active"]
              }
            }}
            afterSlide={onSlideChange}
            renderCenterLeftControls={({ previousSlide }) => (
              <button
                aria-label="previous slide"
                role="button"
                onClick={previousSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="line-arrow-prev" className={styles["prev-arrow"]} />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button
                aria-label="next slide"
                role="button"
                onClick={nextSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="line-arrow-next" className={styles["next-arrow"]} />
              </button>
            )}
          >
            {galleryElements}
          </Carousel>
        </div>
      )}
    </div>
  ) : null;
};

PhotoAlbumSlider.propTypes = {
  imageElements: PropTypes.arrayOf(PropTypes.shape(PhotoAlbumImage.propTypes.storyElement)),
  type: PropTypes.string,
  className: PropTypes.string,
  onSlideChange: PropTypes.func,
  showCount: PropTypes.bool,
  template: PropTypes.string
};

const PhotoAlbum = ({ storyElement, onSlideChange, showCount }) => {
  if (!storyElement) {
    return null;
  }

  return (
    <PhotoAlbumSlider
      imageElements={storyElement["story-elements"]}
      type={storyElement["metadata"]["type"]}
      onSlideChange={onSlideChange}
      showCount={showCount}
    />
  );
};

PhotoAlbum.propTypes = {
  storyElement: PropTypes.shape({
    "story-elements": PropTypes.arrayOf(PropTypes.shape(PhotoAlbumImage.propTypes.storyElement))
  }),
  onSlideChange: PropTypes.func,
  showCount: PropTypes.bool
};

export default PhotoAlbum;

export { PhotoAlbumSlider };
