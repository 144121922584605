import React, { useState } from "react";
import PropTypes from "prop-types";
import CollectionTitle from "../../atoms/CollectionTitle";
import styles from "./styles.m.css";
import StoryCard from "../../atoms/story-card";
import { fetchCollection } from "../../../api";
import StoryCardHorizondalCard from "../../atoms/story-card-horizondal-card";
import { get } from "lodash";
const SixStory6cwithcategory6s = ({
  collection,
  stories = [],
  horizondalView = true,
  cardGrid = true,
  authorName = false,
  utmURL = ""
}) => {
  const { name, items } = collection;
  const pageNumber = 0;
  const limit = 6;
  const [collections, setData] = useState(stories);
  const [collectionslug, setCollectionSlug] = useState(get(items, [0, "slug"], ""));
  const collectionItems = items.filter(item => item && item.type === "collection");
  const [showDeals, SetshowDeals] = useState(false);
  const DealsStory = [
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/electronics/10-best-1-ton-acs?pfrom=homepage",
      headline: "10 Best 1 Ton ACs In India (2023) - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/d943c7e5-d46e-43f8-86e0-1656997f7dc3/Best_1ton_ac.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/electronics/10-best-55-inch-tvs?pfrom=homepage",
      headline: "10 Best 55 Inch TVs In India 2023 - Buyer’s Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/7312cbf1-2103-444e-9834-64d3f48c8747/Best_55inch_TVs.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url:
        "https://www.vikatan.com/vikatan-deals/reviews/furniture/10-best-mattresses-in-india-for-back-pain?pfrom=homepage",
      headline: "10 Best Mattresses For Back Pain In India 2023 - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/4be57780-c5f4-4a51-88a5-df9b01d8d166/Best_mattress_for_back_pain.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/appliances/10-best-refrigerators?pfrom=homepage",
      headline: "10 Best Refrigerators In India 2023 - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/03c0fb21-996f-4b30-b4bd-d1c82886dba9/Best_refrigerators.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/electronics/10-best-5-star-ac?pfrom=homepage",
      headline: "10 Best 5-Star AC (Air Conditioners) in India 2023 - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/14b0ab85-2715-499e-88e0-3882ea2bb75e/Best_5_Star_AC_Air_Conditioner.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url:
        "https://www.vikatan.com/vikatan-deals/reviews/electronics/best-smart-tvs-4k-full-hd-qled-oled?pfrom=homepage",
      headline: "Best Smart TVs: 4K, Full HD, QLED, OLED In India 2023",
      image:
        "https://media.vikatan.com/vikatan/2023-04/0ae825fd-d5e9-46ba-805f-7e63e49ee7d8/Best_4K_TVs_1.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    }
  ];

  const VikatanDeals =
    cardGrid &&
    DealsStory.map((item, idx) => {
      return (
        <a href={item.url} key={idx} className={`${styles["deals-story"]}`}>
          <img src={item.image} alt="" className={`${styles["deals-story-img"]}`} />

          <div className={styles["content"]}>
            <h3 className={`${styles["headline"]} ${styles["truncated"]} `}>{item.headline}</h3>
          </div>
        </a>
      );
    });
  const handleFilter = (slug, index) => {
    fetchCollection(slug, pageNumber, limit, {}, "story").then(response => {
      setData(response["items"]);
      setCollectionSlug(slug);
      SetshowDeals(false);
    });
  };
  const handleDeals = () => {
    SetshowDeals(true);
  };

  const CollectionSlides =
    collectionItems &&
    collectionItems.map(item => (
      <div
        className={`${styles["tag"]} ${item.slug === collectionslug && !showDeals && styles["active"]}`}
        onClick={() => handleFilter(item.slug)}
        key={item.id}
      >
        {item.name}
      </div>
    ));

  return (
    <div className={`container ${styles["collection-wrapper"]}`}>
      <div className={`${styles["title-grid"]}`}>
        <CollectionTitle className={styles.title} title={name} />

        <div className={`${styles["collection-tags"]}`}>
          {CollectionSlides}
          {cardGrid ? (
            <div
              className={`${styles["tag"]} ${showDeals === true ? styles["active"] : styles["in-active"]}`}
              onClick={() => handleDeals("deals")}
            >
              Vikatan Deals
            </div>
          ) : null}
        </div>
      </div>

      {collections && (
        <div
          className={`${styles["story-container"]} ${cardGrid ? styles["cardgrid"] : styles["cardgridfalse"]} ${
            showDeals ? styles["dealsgrid"] : styles["dealsgridfalse"]
          } `}
        >
          {collections.map(
            collection =>
              collection && (
                <StoryCardHorizondalCard
                  key={collection.id}
                  story={collection.story}
                  className={styles["collection-item"]}
                  horizondalView={horizondalView}
                  authorName={authorName}
                  utmURL={utmURL}
                  showDeals={showDeals}
                />
              )
          )}
          {showDeals ? <div className={`${styles["vikatan-deals-outer"]}`}>{VikatanDeals}</div> : null}
        </div>
      )}
    </div>
  );
};

SixStory6cwithcategory6s.propTypes = {
  stories: PropTypes.shape(),
  horizondalView: PropTypes.string,
  cardGrid: PropTypes.string,
  authorName: PropTypes.bool,
  utmURL: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCard.propTypes))
  })
};
SixStory6cwithcategory6s.storyLimit = 4;

export default SixStory6cwithcategory6s;
