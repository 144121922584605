import React from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import { Link } from "@quintype/components";
import StoryCardFeaturedBig from "../../atoms/story-card-featured-big";
import styles from "./styles.m.css";

const CoronaWidgetOneStoryPdf = ({ collection, accentColor }) => {
  const { name, items } = collection;
  return (
    <div className={styles["main-row"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className="container embedded-code">
        <div className={styles["iframe-outer"]}>
          <Iframe
            frameBorder="0"
            width="1200px"
            height="300px"
            className={styles["corona-main"]}
            url="https://view.genial.ly/5e7372d3b77ef32a7c062304"
            type="text/html"
            allowscriptaccess="always"
            allowFullScreen="true"
            scrolling="yes"
            allownetworking="all"
          />
        </div>
      </div>
      <div className={`container`}>
        <div className={styles["corona-content"]}>
          <h2>கொரோனா வைரஸ் என்பது என்ன?</h2>
          <p>
            கொரோனா வைரஸ், <Link href="https://www.vikatan.com/topics/coronavirus">‘கொரோனாவிரிடே’</Link> என்ற வைரஸ்
            குடும்பத்தைச் சேர்ந்தது. இது, மெர்ஸ் (MERS - Middle East Respiratory Syndrome), சார்ஸ் (SARS - Severe Acute
            Respiratory Syndrome) போன்ற மற்ற கொரோனா வைரஸ்களைப்போல தீவிர சுவாசப் பிரச்னைகளை ஏற்படுத்தும். உயிரிழப்புவரை
            ஏற்படலாம். தற்போது சீனாவில் கண்டறியப்பட்டுள்ள கொரோனா வைரஸ் இதற்கு முன் மனிதர்களால் அடையாளம் காணப்படாத புதிய
            வைரஸ். அதைக் குறிக்கும் விதத்தில் நாவல் கொரோனா வைரஸ் (Novel Corona Virus - nCoV) என்று முதலில்
            அழைக்கப்பட்டது.
          </p>
          <h2>COVID-19 பெயர்க்காரணம் :</h2>
          <p>
            <Link href="https://www.vikatan.com/health/healthy/corona-awareness-forward-fact-check">
              உலக சுகாதார மையம் (WHO)
            </Link>
            , இந்த நாவல் கொரோனாவால் ஏற்படும் பாதிப்புக்கு கோவிட் - 19 (COVID - 19) எனப் பெயரிட்டுள்ளது. இந்தப் பெயரில்
            வரும் CO என்பது, கொரோனாவின் (Corona) முதல் இரு எழுத்துகளையும், VI என்பது வைரஸின் (Virus) முதல் இரு
            எழுத்துகளையும், D என்பது நோயின் (Disease) முதல் எழுத்தையும் குறிப்பதாக விளக்கம் தந்திருக்கிறார் அவர்.
            இறுதியாக உள்ள 19 என்ற எண், வைரஸ் கண்டறியப்பட்ட வருடத்தைக் குறிப்பது. டிசம்பர் 31, 2019-ம் தேதியே உலக சுகாதார
            நிறுவனத்துக்கு வைரஸ் குறித்த தகவல்கள் வந்துவிட்டதால், அந்த ஆண்டையே அவர்கள் பெயரில் குறிப்பிட்டுள்ளனர்.
          </p>
          <h2>கொரோனா அறிகுறிகள் :</h2>
          <p>
            <Link href="https://www.vikatan.com/health/healthy/corona-awareness-forward-fact-check">
              பொதுவாக, கொரோனா அறிகுறி
            </Link>
            களாகக் காய்ச்சல், சோர்வு, வறட்டு இருமல் ஆகியவை இருக்கும். இதனுடன் சில நோயாளிகளுக்கு உடல்வலி, மூக்கடைப்பு,
            மூக்கு ஒழுகுதல்,
            <Link href="https://www.vikatan.com/health/healthy/corona-or-normal-cold-cough-flu-how-to-find">
              தொண்டைக் கரகரப்பு மற்றும் வயிற்றுப்போக்கு
            </Link>
            ஆகியவையும் அறிகுறிகளாகத் தென்படுகின்றன என்று ICMR அமைப்பு தெரிவித்தது. அமெரிக்காவின் நோய்க் கட்டுப்பாட்டு
            அமைப்பு (CDC), மூக்கு ஒழுகுதல், குமட்டல் தன்மை மற்றும் வயிற்றுப்போக்கு ஆகியவற்றை அறிகுறிகளாகவும் சமீபத்தில்
            அறிவித்துள்ளது.
          </p>
          <h2>Corona Vaccine கண்டறியப்படும் வரையில், கோவிட் - 19 தடுப்புக்கு என்ன செய்வது?</h2>
          <p>
            சுத்தமாக இருக்க வேண்டும். அடிக்கடி கைகளைக் கழுவ வேண்டும். வைரஸின் வீரியம் தம்மால் குறையும்வரையில்,
            முடிந்தவரை ஒருவரிடமிருந்து இன்னொருவர் குறிப்பிட்ட தூரம் விலகியிருக்க வேண்டும். Physical Distancing எனப்படும்
            விலகியிருத்தல், பரவுதலை வெகுவாகத் தடுக்கும். இது மட்டுமே நம்மிடம் இப்போது இருக்கும் ஒரே வழி.
          </p>
        </div>
      </div>
      <div className="container embedded-code">
        <div className={styles["iframe-second-outer"]}>
          <Iframe
            frameBorder="0"
            width="100%"
            height="600px"
            className={styles["corona-main"]}
            url="https://flo.uri.sh/visualisation/2157362/embed"
            type="text/html"
            allowscriptaccess="always"
            allowFullScreen="true"
            scrolling="no"
            allownetworking="all"
          />
        </div>
      </div>
      <div className="container embedded-code">
        <div className={styles["grid-one-three"]}>
          <div>
            <h1 className={styles["grid-title"]}>Interactive Corner</h1>
            <div className="box-div">
              <div style={{ width: "100%" }}>
                <div className={styles["iframe-outer-with-multiple"]}>
                  <Iframe
                    allownetworking="all"
                    scrolling="yes"
                    allowFullScreen="true"
                    allowscriptaccess="always"
                    type="text/html"
                    url="https://view.genial.ly/5e7ddca57ad2780e3b58e161"
                    className={styles["corona-main"]}
                    height="700px"
                    width="600px"
                    frameBorder="0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className={styles["grid-title"]}>இந்தியாவில் கொரோனாவின் தாக்கம்</h1>
            <div className="box-div">
              <div style={{ width: "100%" }}>
                <div className={styles["iframe-outer-with-multiple"]}>
                  <Iframe
                    allownetworking="all"
                    scrolling="yes"
                    allowFullScreen="true"
                    allowscriptaccess="always"
                    type="text/html"
                    url="https://view.genial.ly/5e79ac02e6072b0dfb662841"
                    className={styles["corona-main"]}
                    height="700px"
                    width="600px"
                    frameBorder="0"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1 className={styles["grid-title"]}>தமிழகத்தில் கொரோனாவின் தாக்கம்</h1>
            <div className="box-div">
              <div style={{ width: "100%" }}>
                <div className={styles["iframe-outer-with-multiple"]}>
                  <Iframe
                    allownetworking="all"
                    scrolling="yes"
                    allowFullScreen="true"
                    allowscriptaccess="always"
                    type="text/html"
                    url="https://view.genial.ly/5e79b1aba6ab580df4788672"
                    className={styles["corona-main"]}
                    height="700px"
                    width="600px"
                    frameBorder="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`container`}>
        <h1>{name}</h1>
        <div className={styles["welcome-grid"]}>
          <div className={styles["corona-pdf"]}>
            <div className="story-grid">
              {items &&
                items.length > 0 && (
                  <StoryCardFeaturedBig
                    className={styles["story-card-featured-big"]}
                    key={items[0].id}
                    story={items[0].story}
                  />
                )}
            </div>
          </div>
          <div className={styles["corona-pdf"]}>
            <a href="https://image.vikatan.com/static/netcore/20200527/Corona pages final 23.05.pdf">
              <img
                src="https://media.vikatan.com/vikatan/2020-05/2d78d34f-5f97-434c-9a37-57f1ca6834e1/Corona.jpg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

CoronaWidgetOneStoryPdf.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardFeaturedBig.propTypes)),
    accentColor: PropTypes.string
  })
};
CoronaWidgetOneStoryPdf.storyLimit = 4;

export default CoronaWidgetOneStoryPdf;
