import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { hasCollectionAccess } from "../../../api";
import { getSubscriptionURL, generateImageSources, getIssueUrlBySlug, checkAppSlug } from "../../utils/utils";
import styles from "./styles.m.css";
// import { Link } from "@quintype/components";
import { format } from "date-fns";

const MagazineBuyCardBase = ({ collection, className = "", cardWithImageZoom = true, subscriptionHost = "" }) => {
  let link = null;
  let linkText = null;
  if (!collection) {
    return null;
  }
  const [granted, setGranted] = useState(false);
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const imgAltText = get(collection, ["metadata", "magazine", "name"], "");

  useEffect(() => {
    hasCollectionAccess(collection.id).then(({ granted }) => {
      setGranted(granted);
    });
  }, []);

  if (granted) {
    link = getIssueUrlBySlug(collection.slug);
    linkText = "Read Now";
  } else {
    link = getSubscriptionURL(subscriptionHost, collection);
    linkText = "Buy Now";
  }

  return (
    <div className={`${styles["magazine-buy-card"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}>
      <div className={styles["image-container"]}>
        <ResponsiveImageWithFallback
          className={`${styles["image-wrapper"]} qt-figure`}
          slug={coverImageS3Key}
          metadata={coverImageMetaData}
          alt={imgAltText}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [7, 10], screenWidthCoverage: 0.17 },
            { aspectRatio: [7, 10], screenWidthCoverage: 0.11 }
          )}
        />
        {link &&
          linkText && (
            <a href={link} className={`${styles["buy-btn"]} ${granted ? styles["darker-bg"] : ""}`}>
              {linkText}
            </a>
          )}
      </div>
    </div>
  );
};

export const MagazineBuyCard = connect(state => {
  return { subscriptionHost: get(state, ["qt", "config", "vikatanHosts", "subscription"]) };
})(MagazineBuyCardBase);

export const MagazineCard = ({
  collection,
  className = "",
  cardWithImageZoom = true,
  homeMagazine = false,
  audioMagazine = false,
  utmURL = "magazine"
}) => {
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "metadata"], "");
  const imgAltText = get(collection, ["metadata", "magazine", "name"], "");
  const issueDate = get(collection, ["collection-date"]);
  const pagePath = get(collection, ["pagePath"], "#");
  const checkSlug = checkAppSlug();
  let SourceLink = "";

  if (checkSlug) {
    SourceLink = `${pagePath}`;
  }

  return (
    <div
      className={`${styles["magazine-buy-card"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""} ${
        homeMagazine ? styles["homemagazinetrue"] : styles["homemagazinefalse"]
      } ${audioMagazine ? styles["audiomagazinetrue"] : styles["audiomagazinefalse"]}`}
    >
      <div className={styles["image-container"]}>
        <a
          href={`${pagePath}?pfrom=${"audio-magazine"}`}
          externalLink={SourceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ResponsiveImageWithFallback
            className={`${styles["image-wrapper"]} qt-figure`}
            slug={coverImageS3Key}
            metadata={coverImageMetaData}
            alt={imgAltText}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [7, 10], screenWidthCoverage: 0.4 },
              { aspectRatio: [7, 10], screenWidthCoverage: 0.2 }
            )}
          />
          {!homeMagazine && !audioMagazine && issueDate ? (
            <div className={styles["magazine-published-date"]}>{format(new Date(issueDate), "dd/MM/yyyy")}</div>
          ) : null}
        </a>
      </div>
    </div>
  );
};

MagazineBuyCardBase.propTypes = {
  subscriptionHost: PropTypes.string,
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  buyLink: PropTypes.string,
  collection: PropTypes.shape({
    metadata: PropTypes.shape({
      "cover-image": PropTypes.shape({
        "cover-image-metadata": PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number
        }),
        "cover-image-s3-key": PropTypes.string
      }),
      magazine: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

MagazineCard.propTypes = {
  className: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  collection: MagazineBuyCardBase.propTypes.collection,
  homeMagazine: PropTypes.bool,
  utmURL: PropTypes.string,
  audioMagazine: PropTypes.bool
};

export default MagazineBuyCard;
