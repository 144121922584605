import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";
import AudioCollectionCard from "../../atoms/audio-collection-card";
import { LoadmoreAudiostories } from "../../../api";

const VikatanPlayHome = ({ collection, numberOfCardsInViewDesktop = 3, accentColor }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const collectionSlug = "vikatan-play-latest-audiobooks";
    const limit = 4;
    const offset = 0;

    LoadmoreAudiostories(collectionSlug, limit, offset)
      .then(items => {
        setItems(items.items);
      })
      .catch(error => {
        console.error("Error fetching VikatanPlayStories:", error);
      });
  }, []);

  if (!items || !items.length) {
    return null;
  }

  const mainCollection = (
    <div className={styles["main-collection"]}>
      <div className={styles["vikatan-play-mobile"]}>
        <img src="https://gumlet.assettype.com/vikatan/2024-11-21/0bv6g99m/vikatan-play-mobile.png" alt="" />
      </div>

      <div className={styles["text-wrapper"]}>
        <div className={`${styles["bundle-text"]} hidden-mobile`}>
          <img src="https://gumlet.assettype.com/vikatan/2024-11-21/3owo3zae/vikatan-play-icon.png" alt="" />
          <h3 className={styles["headline"]}>விகடனின் கிளாசிக் படைப்புகள் இப்போது ஆடியோ புத்தகங்களாக!</h3>
          <p className={styles["description"]}>
            விகடன் App-ஐ இன்ஸ்டால் செய்து ஆடியோ புத்தகங்களை இலவசமாக கேட்டு மகிழ்ந்திடுங்கள்!
          </p>
          <div className={styles["store-icons"]}>
            <a href="https://vikatanmobile.page.link/home-page">
              <img src="https://gumlet.assettype.com/vikatan/2024-11-21/ln69siy0/play-app-store.png" />
            </a>
            <a href="https://vikatanmobile.page.link/home-page">
              <img src="https://gumlet.assettype.com/vikatan/2024-11-21/bdgi83io/vikatan-play-store.png" />
            </a>
          </div>
        </div>
        <div className={`${styles["bundle-text"]} ${styles["mobile-logo"]} hidden-desktop`}>
          <img src="https://gumlet.assettype.com/vikatan/2024-11-21/2q3f2ojd/viktan-play-mobile.png" alt="" />
        </div>
      </div>
    </div>
  );

  const itemsList = items.map((item, index) => {
    return (
      <AudioCollectionCard
        key={item.id}
        collection={item}
        className={`${styles.card} ${index === 0 ? styles["first-item"] : ""}`}
        showTitleAndMetaDesc={true}
        numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
        VikatanPlayHome={true}
      />
    );
  });

  return (
    <div className={`${styles["bundle-wrapper"]} container`}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className={`${styles["carousel-wrapper"]}`}>
          <div>{mainCollection}</div>
          <div className={`${styles["headline-mobile"]} hidden-desktop`}>
            <h3 className={styles["headline"]}>விகடனின் கிளாசிக் படைப்புகள் இப்போது ஆடியோ புத்தகங்களாக!</h3>
          </div>
          <div className={styles["other-collection"]}>{itemsList}</div>
          <div className={`${styles["bundle-mobile-content"]} hidden-desktop`}>
            <p className={styles["description"]}>
              விகடன் App-ஐ இன்ஸ்டால் செய்து ஆடியோ புத்தகங்களை இலவசமாக கேட்டு மகிழ்ந்திடுங்கள்!
            </p>
            <div className={styles["store-icons"]}>
              <a href="https://vikatanmobile.page.link/home-page">
                <img src="https://gumlet.assettype.com/vikatan/2024-11-21/ln69siy0/play-app-store.png" />
              </a>
              <a href="https://vikatanmobile.page.link/home-page">
                <img src="https://gumlet.assettype.com/vikatan/2024-11-21/bdgi83io/vikatan-play-store.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VikatanPlayHome.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number
};

export default VikatanPlayHome;
