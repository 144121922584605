import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import StoryCardDLTextOverley from "../../atoms/story-card-dl-text-overley";

import styles from "./styles.m.css";
import { PGAdwithAd } from "../ad-free";
import MainCardStory from "../../atoms/main-card-story";
import { Link, WithLazy } from "@quintype/components";
import Media from "react-media";

const FourStorySponcerContentOneAdOneWidget = ({ collection, pagetype = "", index, stories, sponsercard = true }) => {
  if (stories.length < 1) {
    return null;
  }

  const [userType = "F", setType] = useState(false);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType);
    });
  }, []);

  const stack = stories.slice(0, 4).map((story, index) => (
    <MainCardStory
      story={story}
      key={story.id}
      sponsercard={sponsercard}
      authorName={false}
      utmURL={"home-twostories-row"}
      className={`
          ${styles["line-separater"]}
        `}
    />
  ));

  return (
    <div className={`${styles["base"]}`}>
      <div className={`${styles["base-section"]} container`}>
        <div className={`${styles["story-grid"]}`}>
          <div className={styles["left-panel"]}>
            <div className={styles["main-content"]}>{stack}</div>
          </div>
          <div className={styles["right-panel"]}>
            {userType === "P" || userType === "T" ? (
              <div className={styles["short-stories"]}>
                <Link className={styles["series-wrapper-link"]} href="/short-stories?pfrom=homepage-mainrow-series">
                  <img
                    src="https://media.vikatan.com/vikatan/2023-06/3bb81e58-faad-4016-ab1e-6e9a1f826142/short_stories_home.png?format=webp&w=300&dpr=1.0"
                    alt="series"
                    width="350px"
                    height="460px"
                    className={styles["shortstories-image-paid-user"]}
                  />
                </Link>
              </div>
            ) : (
              <div className="hidden-mobile">
                <WithLazy>
                  {() => (
                    <Media query="(min-width: 768px)">
                      {matches =>
                        matches ? (
                          <div>
                            {userType === "P" || userType === "T" || userType === "R" ? (
                              " "
                            ) : (
                              <div className="ad_300_250 ad-top-bottom-space">
                                <PGAdwithAd
                                  adId={`Vikatan2023_Desktop_HP_RightMID2_336x280`}
                                  adSize={[[300, 250], [336, 280]]}
                                  minHeight={280}
                                  adDivId="div-ad-desk-3849069-1694280367-0"
                                  class=""
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      }
                    </Media>
                  )}
                </WithLazy>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FourStorySponcerContentOneAdOneWidget.propTypes = {
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape(StoryCardDLTextOverley.propTypes))
  }),
  stories: PropTypes.arrayOf(StoryCardDLTextOverley.propTypes.story),
  pagetype: PropTypes.string,
  index: PropTypes.number,
  sponsercard: PropTypes.bool
};

export default FourStorySponcerContentOneAdOneWidget;
