import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";

import SubdomainsBigStoryCard from "../../atoms/big-story-card-subdomain";
import SubdomainsSmallStoryCard from "../../atoms/small-story-card-subdomains";

const IplCollection = ({
  collection,
  topFullView = true,
  homeSec = true,
  homeMatchlist = true,
  iplcollection = true
}) => {
  const [pageName, setPageName] = useState("home");
  const mobileStoryCount = 0;

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);
  const items = collection && collection.items ? collection.items : [];

  return (
    <div className={styles.base}>
      <div className={styles["main-wc"]}>
        <div className={`${styles["mobile-container"]} container`}>
          <div className={styles["main-wc-banner"]}>
            <div className="hidden-desktop">
              <a href="https://sports.vikatan.com/cricket/icc-t20-world-cup" rel="noopener noreferrer" target="_blank">
                <img
                  src="https://media.vikatan.com/vikatan/2024-06/987e0810-27f8-4d55-b291-1ade27aa8da2/Vikatan_Sathya_Mans_t20_world_cup_article_mobile_top_banner_375x120.png?format=webp&w=310&dpr=1.0"
                  alt=""
                />
              </a>
            </div>
            <div className="hidden-mobile">
              <a href="https://sports.vikatan.com/cricket/icc-t20-world-cup" rel="noopener noreferrer" target="_blank">
                <img
                  src="https://media.vikatan.com/vikatan/2024-06/a23cbef0-6348-4ca8-b7d8-aa5d9b8608e3/Vikatan_Sathya_Mans_t20_world_cup_banner_1140x90.png?format=webp&w=1140&dpr=1.0"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className={styles["main-stories-grid"]}>
            <div className={styles["main-slider-collection"]}>
              <div className={styles["story-base"]}>
                {items &&
                  items.length > 0 && (
                    <SubdomainsBigStoryCard
                      className={styles["story-card-featured-big"]}
                      key={items[0].id}
                      story={items[0].story}
                      titleColor="#fff"
                      pFrom={`${pageName}-story-card-slider`}
                      iplcollection={iplcollection}
                    />
                  )}
                {items &&
                  items.length > 1 &&
                  items.slice(1, 7).map((items, index) => (
                    <SubdomainsSmallStoryCard
                      key={items.id}
                      story={items.story}
                      titleColor="#fff"
                      pFrom={`${pageName}-story-card-slider`}
                      className={`
          ${styles[index + 1 >= mobileStoryCount ? "" : ""]}
          ${styles["line-separater"]}
          ${styles[index + 2 === mobileStoryCount ? "skip-line" : ""]}
          ${styles[index % 2 === 0 ? "withborder" : "withborder-left"]}
        `}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IplCollection.propTypes = {
  accentColor: PropTypes.string,
  topFullView: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  homeSec: PropTypes.string,
  homeMatchlist: PropTypes.string,
  iplcollection: PropTypes.bool
};

export default IplCollection;
