import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import FourCollectionSixStoryHorizontalCardStoryStack from "../../atoms/four-collection-6s-horizontal-card-story-stack";

import styles from "./styles.m.css";

import { wrapCollectionLayout } from "@quintype/components";

import CollectionTitle from "../../atoms/CollectionTitle";
import Gamessection from "../../atoms/GamesSection";
import Media from "react-media";
import { PGAdwithAd } from "../ad-free";

const WrappedStoryStack = wrapCollectionLayout(FourCollectionSixStoryHorizontalCardStoryStack);

const mobileStoryCount = 6;

const FourcollectionStoriesGameSection = ({ collection, accentColor, cardGrid = true }) => {
  const { name, items } = collection;

  const [userType = "F", setType] = useState(false);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      setType(user.userType);
    });
  }, []);

  const [selectedCollectionSlug, setSelectedCollectionSlug] = useState(0);
  const [selectedCollectionStories, setSelectedCollectionStories] = useState(items[0]);
  const [showDeals, SetshowDeals] = useState(false);
  const DealsStory = [
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/electronics/10-best-1-ton-acs?pfrom=homepage",
      headline: "10 Best 1 Ton ACs In India (2023) - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/d943c7e5-d46e-43f8-86e0-1656997f7dc3/Best_1ton_ac.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/electronics/10-best-55-inch-tvs-2?pfrom=homepage",
      headline: "10 Best 55 Inch TVs In India 2023 - Buyer’s Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/7312cbf1-2103-444e-9834-64d3f48c8747/Best_55inch_TVs.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url:
        "https://www.vikatan.com/vikatan-deals/reviews/furniture/10-best-mattresses-in-india-for-back-pain?pfrom=homepage",
      headline: "10 Best Mattresses For Back Pain In India 2023 - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/4be57780-c5f4-4a51-88a5-df9b01d8d166/Best_mattress_for_back_pain.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/appliances/10-best-refrigerators?pfrom=homepage",
      headline: "10 Best Refrigerators In India 2023 - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/03c0fb21-996f-4b30-b4bd-d1c82886dba9/Best_refrigerators.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url: "https://www.vikatan.com/vikatan-deals/reviews/electronics/10-best-5-star-ac?pfrom=homepage",
      headline: "10 Best 5-Star AC (Air Conditioners) in India 2023 - Buyer's Guide",
      image:
        "https://media.vikatan.com/vikatan/2023-04/14b0ab85-2715-499e-88e0-3882ea2bb75e/Best_5_Star_AC_Air_Conditioner.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    },
    {
      url:
        "https://www.vikatan.com/vikatan-deals/reviews/electronics/best-smart-tvs-4k-full-hd-qled-oled?pfrom=homepage",
      headline: "Best Smart TVs: 4K, Full HD, QLED, OLED In India 2023",
      image:
        "https://media.vikatan.com/vikatan/2023-04/0ae825fd-d5e9-46ba-805f-7e63e49ee7d8/Best_4K_TVs_1.jpg?rect=25%2C0%2C3539%2C2359&auto=format%2Ccompress&format=webp&w=110&dpr=1.6"
    }
  ];

  const VikatanDeals =
    cardGrid &&
    DealsStory.map((item, idx) => {
      return (
        <a href={item.url} key={idx} className={`${styles["deals-story"]}`}>
          <img src={item.image} alt="" className={`${styles["deals-story-img"]}`} />

          <div className={styles["content"]}>
            <h3 className={`${styles["headline"]} ${styles["truncated"]} `}>{item.headline}</h3>
          </div>
        </a>
      );
    });

  const leftPanelCollections = items.slice(0, 3);

  const handleCollectionClick = (slug, index) => {
    setSelectedCollectionSlug(index);
    setSelectedCollectionStories(items[index]);
    SetshowDeals(false);
  };

  const handleDeals = () => {
    SetshowDeals(true);
    setSelectedCollectionSlug(false);
    setSelectedCollectionStories(false);
  };

  return (
    <div className={styles["twelve-story-base"]}>
      <div className={`${styles["six-story-collection"]} container`}>
        <div className={styles["left-panel"]}>
          <div className={styles["title-grid"]}>
            <CollectionTitle className={styles.title} title={name}>
              {collection.name}
            </CollectionTitle>
            <div className={styles["collection-tags"]}>
              {leftPanelCollections.map((item, index) => (
                <div
                  className={`${selectedCollectionSlug === index && !showDeals ? styles["active"] : styles["tag"]}`}
                  onClick={() => handleCollectionClick(item.slug, index)}
                  key={item.id}
                >
                  {item.name}
                </div>
              ))}
              {cardGrid ? (
                <div
                  className={`${styles["tag"]} ${showDeals === true ? styles["active"] : styles["in-active"]}`}
                  onClick={() => handleDeals("deals")}
                >
                  Vikatan Deals
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={`${styles["story-container"]} ${cardGrid ? styles["cardgrid"] : styles["cardgridfalse"]} ${
              showDeals ? styles["dealsgrid"] : styles["dealsgridfalse"]
            } `}
          >
            {leftPanelCollections &&
              selectedCollectionStories && (
                <WrappedStoryStack
                  mobileStoryCount={mobileStoryCount}
                  collection={selectedCollectionStories}
                  showDeals={showDeals}
                />
              )}
            {showDeals ? <div className={`${styles["vikatan-deals-outer"]}`}>{VikatanDeals}</div> : null}
          </div>
        </div>
        <div className={styles["right-panel"]}>
          <div className="hidden-desktop">
            <Media query="(max-width: 767px)">
              {matches =>
                matches && (
                  <div>
                    <div>
                      {userType === "P" || userType === "T" || userType === "R" ? (
                        " "
                      ) : (
                        <div className="ad_300_250 ad-top-bottom-space">
                          <PGAdwithAd
                            adId={`Vikatan2023_Mobile_HP_MID2_336x280`}
                            adSize={[[336, 280], [300, 250]]}
                            minHeight={280}
                            adDivId="div-ad-desk-3849069-169028052743-4"
                            class=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
            </Media>
          </div>
          <Gamessection />
        </div>
      </div>
    </div>
  );
};

FourcollectionStoriesGameSection.propTypes = {
  accentColor: PropTypes.string,
  cardGrid: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        type: PropTypes.string,
        slug: PropTypes.string,
        readMoreText: PropTypes.string
      })
    )
  })
};

export default FourcollectionStoriesGameSection;
