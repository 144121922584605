import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
export default function Gamessection({ className = "" }) {
  const [ToggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => (ToggleState === index ? className : "");

  return (
    <div className={`${styles["game-wrapper"]} ${className}`}>
      <Tabs>
        <TabList className={styles["tablist"]}>
          <Tab className={`${styles["tab"]} ${styles[getActiveClass(1, "active-tabs")]}`} onClick={() => toggleTab(1)}>
            Game 1
          </Tab>
          <Tab className={`${styles["tab"]} ${styles[getActiveClass(2, "active-tabs")]}`} onClick={() => toggleTab(2)}>
            Game 2
          </Tab>
          <Tab className={`${styles["tab"]} ${styles[getActiveClass(3, "active-tabs")]}`} onClick={() => toggleTab(3)}>
            Game 3
          </Tab>
        </TabList>
        <TabPanel className={`${styles["tabpanel"]} ${getActiveClass(1, "active-content")}`}>
          <div className={styles["game-card"]}>
            <div className={styles["game-header"]}>
              <img
                src="https://media.vikatan.com/vikatan/2022-09/bb09acda-624b-43d9-82ce-0914e6a76982/Logo_2e6798cafbd8614a0664.png?w=150"
                alt="சொல்லியடி"
                width="150px"
                height="46px"
              />
              <Link
                href="https://solliyadi.vikatan.com?pfrom=homepage-mainrow-games"
                className={styles["cta-btn"]}
                target=""
              >
                Play Now
              </Link>
            </div>
            <div className={styles["game-content"]}>
              <img
                src="https://media.vikatan.com/vikatan/2022-07/ef1a0fe0-7090-4d84-b75a-9b4fd556f89e/unnamed.png?w=300"
                alt="சொல்லியடி விளையாட்டு"
                width="260px"
                height="207px"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel className={`${styles["tabpanel"]} ${getActiveClass(2, "active-content")}`}>
          <div className={styles["game-card"]}>
            <div className={styles["game-header"]}>
              <img
                src="https://media.vikatan.com/vikatan/2022-09/851222c6-6bb6-4136-ae87-dc4128abe039/logo_876c56c8971a2655d6dd.png?w=150"
                alt="வார்த்தையோடு விளையாடு"
                width="150px"
                height="46px"
              />
              <Link
                href="https://games.vikatan.com/varthaiyodu-vilaiyadu?pfrom=homepage-mainrow-games"
                className={styles["cta-btn"]}
              >
                Play Now
              </Link>
            </div>
            <div className={styles["game-content"]}>
              <img
                src="https://media.vikatan.com/vikatan/2022-07/9f0c513d-eb64-4f7a-bfc5-32931373d865/varthaiyodu_vilaiyadu.png?w=300"
                alt="Vaarthaiyodu Vilaiyadu"
                width="184px"
                height="190px"
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel className={`${styles["tabpanel"]} ${getActiveClass(3, "active-content")}`}>
          <div className={styles["game-card"]}>
            <div className={styles["game-header"]}>
              <img
                src="https://media.vikatan.com/vikatan/2022-09/27138631-7943-4e89-a7fa-092888cc4cec/solvettai_logo.png?w=150"
                alt="சொல் வேட்டை"
                width="150px"
                height="46px"
              />
              <Link
                href="https://games.vikatan.com/word-search?pfrom=homepage-mainrow-games"
                className={styles["cta-btn"]}
              >
                Play Now
              </Link>
            </div>
            <div className={styles["game-content"]}>
              <img
                src="https://media.vikatan.com/vikatan/2022-09/1faf16ba-d546-4d8d-9cf2-f4a50feb85dd/solveytai.png?w=300"
                alt="சொல் வேட்டை"
                width="184px"
                height="190px"
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

Gamessection.propTypes = {
  className: PropTypes.string
};
