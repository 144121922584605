import React from "react";
import PropTypes from "prop-types";
import SliderMain from "../../atoms/two-story-slider";
import CollectionBundleItem from "../../atoms/collection-bundle-item";
import Carousel from "../../atoms/carousel";
import { get } from "lodash";

import styles from "./styles.m.css";

const SliderCollectionStoriesBundle = ({ stories }) => {
  return (
    <div className={styles["slider-collection-stories-bundle"]}>
      <div className={styles["slider-stories-wrap"]}>
        <SliderMain stories={stories.slice(0, 5)} />
      </div>
      <div className={styles["second-slider-stories-wrap"]}>
        <SliderMain stories={stories.slice(5, 10)} />
      </div>
      <div className={styles["image-slider"]}>
        <h3 className={styles["head"]}>Cartoon Corner</h3>
        <Carousel
          className={`${styles["first-slider"]}`}
          options={{
            autoplay: 3000,
            type: "carousel"
          }}
          renderCenterLeftControls={({ previousSlide }) => {
            return null;
          }}
          renderCenterRightControls={({ nextSlide }) => {
            return null;
          }}
          renderBottomCenterControls={({ currentSlide, goToSlide }) => {
            return null;
          }}
        >
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200326/vadhandhi.jpg" />
          </div>
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200326/edappCorona.jpg" />
          </div>
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200422/CARTOON.jpg" />
          </div>
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200422/tr.jpg" />
          </div>
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200326/CoronaModi.jpg" />
          </div>
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200422/ca.jpg" />
          </div>
          <div>
            <img src="https://image.vikatan.com/static/netcore/20200326/india%20(1).jpg" />
          </div>
          <div>
            <img src="https://media.vikatan.com/vikatan/2020-05/8477f463-251c-4394-b791-6f82e319c9b4/CartoonTasmac.jpg" />
          </div>
          <div>
            <img src="https://media.vikatan.com/vikatan/2020-05/7bedd419-18fa-404f-8ac8-2706bbfe43f9/StalinTasmaccc.jpg" />
          </div>
          <div>
            <img src="https://media.vikatan.com/vikatan/2020-06/6928a900-3e47-4a5b-9284-d6df7cc52c22/Modi.jpg" />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

SliderCollectionStoriesBundle.propTypes = {
  stories: SliderMain.propTypes.stories
};

const DoubleStorySlider = ({ collection, accentColor, pagetype = "", magazine, config }) => {
  const headline = get(collection, ["name"], "");
  if (!collection.items) {
    return null;
  }
  if (!headline) {
    return null;
  }
  const stories = collection.items.filter(item => item && item.type === "story").map(item => item.story);
  return (
    <div className={styles["main-row"]} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <h3 className={styles["headline"]}>{headline}</h3>
        <div className="row">
          {stories && stories.length > 0 && <SliderCollectionStoriesBundle stories={stories.slice(0, 10)} />}
        </div>
      </div>
    </div>
  );
};

DoubleStorySlider.propTypes = {
  accentColor: PropTypes.string,
  collection: CollectionBundleItem.propTypes.collection,
  pagetype: PropTypes.string,
  magazine: PropTypes.array,
  config: PropTypes.shape()
};

export default DoubleStorySlider;
