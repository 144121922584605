import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getStoryHeadline } from "../../utils/utils";

import styles from "./styles.m.css";
import MainCardStory from "../../atoms/main-card-story";
import CricketSchedule from "../wc-schedule-table";
import WCPointTable from "../wc-point-table";
import WCMatchList from "../wc-match-list";

const WorldCupCollection = ({ collection, topFullView = true, homeSec = true, homeMatchlist = true }) => {
  console.log("SC--->", collection);

  const [pageName, setPageName] = useState("home");

  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);

  const slides = collection.items
    .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
    .slice(0, 5)
    .map(({ story }) => (
      <MainCardStory
        key={story.id}
        story={story}
        className={styles.card}
        topFullView={topFullView}
        pFrom={`${pageName}-story-card-slider`}
      />
    ));

  return (
    <div className={styles.base}>
      <div className={styles["main-wc"]}>
        <div className={`${styles["mobile-container"]} container`}>
          <div className={styles["main-wc-banner"]}>
            <div className="hidden-desktop">
              <a href="/cricket/icc-world-cup-2023" rel="noopener noreferrer" target="_blank">
                <img
                  src="https://media.vikatan.com/vikatan/2023-11/db96813b-282b-45ac-ad5c-97e699f006db/Vikatan_Sathay_sports_Article_top_banner_375x120__1_.png?format=webp&w=310&dpr=1.0"
                  alt=""
                />
              </a>
            </div>
            <div className="hidden-mobile">
              <a href="/cricket/icc-world-cup-2023" rel="noopener noreferrer" target="_blank">
                <img
                  src="https://media.vikatan.com/vikatan/2023-11/cd6949c0-c863-4f9d-ad4f-66e66829c3a8/vikatan_sathya_leaderboard.png?format=webp&w=1000&dpr=1.0"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className={styles["main-stories-grid"]}>
            <div className={styles["main-slider-collection"]}>{slides}</div>
            <CricketSchedule />
          </div>
          <div>
            <WCMatchList homeMatchlist={homeMatchlist} />
          </div>
          <div className={styles["wcpoint-table"]}>
            <WCPointTable homeSec={homeSec} />
          </div>
        </div>
      </div>
    </div>
  );
};

WorldCupCollection.propTypes = {
  accentColor: PropTypes.string,
  topFullView: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  homeSec: PropTypes.string,
  homeMatchlist: PropTypes.string
};

export default WorldCupCollection;
